import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "animate.css";
import "@fontsource/league-spartan";
import "@fontsource/league-spartan/100.css";
import "@fontsource/league-spartan/400.css";
import "@fontsource/marvel";
import ReactGA from "react-ga4";

const urlSearchParams = new URLSearchParams(window.location.search);

ReactGA.initialize(
  urlSearchParams.get("trackingOff")
    ? ""
    : process.env.REACT_APP_GA_TRACKING_ID!
);

ReactGA.send({ hitType: "pageview", page: (window.location.pathname + window.location.search)});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
